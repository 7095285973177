import { useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import { loadUser, setupAutoRefresh } from "./redux/actions/auth";
import Preloader from "./components/Preloader";

// Lazy load components
const PrivateRoute = lazy(() => import("./components/routing/PrivateRoute"));
const Footer = lazy(() => import("./components/Footer"));
const Home = lazy(() => import("./pages/Home"));
const User = lazy(() => import("./pages/User"));
const Banner = lazy(() => import("./pages/Banner"));
const Faqs = lazy(() => import("./pages/Faqs"));
const Report = lazy(() => import("./pages/Report"));
const Service = lazy(() => import("./pages/Service"));
const Tariff = lazy(() => import("./pages/Tariff"));
const TeamMember = lazy(() => import("./pages/TeamMember"));
const Contact = lazy(() => import("./pages/Contact"));
const Login = lazy(() => import("./pages/Login"));

function AppContent() {
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(setupAutoRefresh());
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Router>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/" replace />
              ) : (
                <Suspense fallback={<Preloader />}>
                  <Login />
                </Suspense>
              )
            }
          />
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Suspense fallback={<Preloader />}>
                  <PrivateRoute />
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/users/*" element={<User />} />
            <Route path="/banners/*" element={<Banner />} />
            <Route path="/faqs/*" element={<Faqs />} />
            <Route path="/reports/*" element={<Report />} />
            <Route path="/services/*" element={<Service />} />
            <Route path="/tariffs/*" element={<Tariff />} />
            <Route path="/team-members/*" element={<TeamMember />} />
            <Route path="/contacts/*" element={<Contact />} />
          </Route>
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<Preloader />} persistor={persistor}>
        <AppContent />
      </PersistGate>
    </Provider>
  );
}

export default App;
